<template>
  <div id="app-container">
    <router-view/>
  </div>
</template>

<style>
    @import url(./assets/main.css);

    #app-container {
        background-color: var(--background);
        height: 100vh;
        width: 100%;
        color: var(--text);
        overflow-y: hidden;
    }
</style>
